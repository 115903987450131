import React from "react";
import emotionReset from "emotion-reset";
import { ThemeProvider, Global, css } from "@emotion/react";

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={{}}>
    <Global
      styles={css`
        @import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

        ${emotionReset}

        html {
          -webkit-font-smoothing: antialiased;
          font-family: "Inter", sans-serif;
          background: #121212;
          color: white;
          font-weight: 400;
          font-size: 24px;
        }

        body {
          overscroll-behavior-y: none;
        }

        p {
          line-height: 1.4;
        }

        p:not(:last-child) {
          margin-bottom: 1rem;
        }

        .t0,
        h1 {
          font-size: 60px;
          font-weight: 600;
        }

        .t1,
        h2 {
          font-size: 40px;
          font-weight: 600;
        }

        .t2,
        h3 {
          font-size: 24px;
          font-weight: 600;
        }

        .t3,
        h4 {
          font-size: 24px;
        }

        .t4,
        h5 {
          font-size: 16px;
          font-weight: 600;
        }

        .t5,
        h6 {
          font-size: 12px;
          font-weight: 600;
          text-transform: uppercase;
        }

        hr {
          max-width: 1537px;
          height: 1px;
          border: none;
          background: rgba(255, 255, 255, 0.1);
          margin: 64px auto;
        }
      `}
    />
    {element}
  </ThemeProvider>
);
